
@font-face {
  font-family: "beatrice";
  src: url("./assets/beatrice.ttf");
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.loader{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  gap: 25px;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.img{
  background: no-repeat center/cover;
  position: relative;
  height: auto;
}
.img-about{
  background: no-repeat center/cover;
  position: relative;
  height: auto;
}
.img-portfolio{
  height: auto;
}
a{
  text-decoration: none;
  color: black;
}
a:focus{
  color: black;
}
a:active{
  color: black;
}
ul{
  list-style: none;
  padding-inline-start: 0px;
}
p{
  margin-block-start: 0em;
    margin-block-end: 0em;
}
.main-title-section-projects{
  font-family:"beatrice";
font-size: 45px;
color: white;
width: 100%;
display: flex;
justify-content: center;
padding-top: 2%;
padding-bottom: 5%;
font-weight: 500;
}