@keyframes confirmation {
    0%{
        opacity: 0;
    }25%{
        opacity: 0.25;
    }50%{
        opacity: 0.5;
    }75%{
        opacity: 0.75;
    }100%{
        opacity: 1;
    }
}
@keyframes hideConfirmation {
    0% { opacity: 1; }
    100% { opacity: 0; }
    }

.container_confirmation_language{
    height: 100vh;
    width: 100%;
    background-color: black;
    position: fixed;
    z-index: 999999999;
    animation: confirmation 0.5s both;
}
.content_confirmation_language{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 3rem;
    color: white;
    flex-wrap: nowrap;
    white-space: nowrap;
}
.hide-confirmation {
    animation: hideConfirmation 0.6s both;
}
/* Change languages button */

.container_change_languages{
    position: fixed;
    top: 12%;
    right: 3%;
    z-index: 100;
}
.icon_change_languages{
    font-size: 40px;
}
.icon_change_languages:hover{
    cursor: pointer;
}
.button_change_languages{
    color: black;
    background-color: transparent;
    border: none;
    width: 100%;
    background-color: rgb(244, 243, 243);
    border-radius: 15px 15px 15px 15px;
    padding: 1rem;
}
.button_change_languages:hover{
    transform: scale(1.1);
    transition: all ease 1s;
    cursor: pointer;
}
.button_change_languages:focus{
    color: black;
    background-color: transparent;
    border: none;
    width: 100%;
    background-color: rgb(244, 243, 243);
    border-radius: 15px 15px 15px 15px;
    padding: 1rem;
}


.button_change_languages:hover .icon_change_languages{
    transform: scale(1.1);
    color: black;
    transition: all ease 0.5s;
}
.language_precision{
    font-size: 1rem;
    font-weight: bold;
}
@media(max-width: 843px){
    .icon_change_languages{
        font-size: 30px;
    }
}
@media(max-width: 647px){
    .content_confirmation_language{
        font-size: 2rem;
    }
    .icon_change_languages{
        font-size: 20px;
    }
}
@media(max-width: 425px){
    .content_confirmation_language{
        font-size: 1.5rem;
    }
}
@media(max-width: 325px){
    .content_confirmation_language{
        font-size: 1rem;
    }
}