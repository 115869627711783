.navbar{
    display: flex;
    flex: wrap;
    align-items: center;
    position: fixed;
    width: 100%;
    min-height: 75px;
    max-height: 75px;
    background-color: #fff;
    color: #000;    
    z-index: 1000;
    top: 0; 
}
.grid-test{
    width: 100%;
    display: grid;
    grid-template-columns: 0.2fr 0.6fr 0.2fr;
    padding-top: 60px;
    margin-bottom: 60px;
}
.navbar_links{
    display: flex;
    justify-content: center;
    font-size: 25px;
    width: 100%;
}
.navbar_link{
    padding: 1rem 1rem 1em;
    color: black;
    text-decoration: none;
}
.link{
    color: black;
    text-decoration: none; 
}
.navbar_link:hover{
    font-weight: 500;
    cursor: pointer;
}
.navbar_burger{
    width: 40px;
    height: 40px;
    background: transparent;
    border: none;
    color: inherit;
    margin-top: 15px;
}
.navbar_logo{
    font-family: Beatrice;
    font-size: 25px;
    padding-left: 4%;
    padding-top: 45px;
    margin-bottom: 40px;
    color: black;
    text-decoration: none;
}
.navbar_logo:hover{
    font-weight: 600;
    cursor: pointer;
}
.navbar_logo:focus{
    color: black;
    text-decoration: none;
}
li{
    list-style: none;
}
@media screen and (max-width: 1365px){
    .navbar_links{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: fixed;
        right: -100vw;
        bottom: -5vh;
        height: 100vh;
        width: 0;
        padding: 0.5rem;
        visibility: hidden;
        background-color: #fff;
        transition: all .8s ease-out;
    }
    .grid-test{
        grid-template-columns: 0.5fr 0.5fr;
    }
    .show-nav .navbar_links{
        right: -0vw;
        width: 100vw;
        visibility: visible;
    }
    .navbar_logo{
        font-size: 25px;
        padding-left: 4%;
        padding-top: 0px;
        margin-bottom: 5px;
    }
    /* bonus */
    .navbar_item::after{
        content: "";
        display: block;
        margin: 0 auto;
        width: 3vw;
        height: 1px;
        background: #000;
    }
    .navbar_item:last-child::after{
        display: none;
    }
    /*-------------*/

    .navbar_link{
        display: block;
        padding: 1.5rem;
        font-size: 6vw;
    }
    .navbar_burger{
        display: block;
        position: fixed;
        top: 0.2rem;
        right: 1rem;
    }
    .navbar_burger:hover{
        cursor: pointer;
    }
    .burger-bar, .burger-bar::before, .burger-bar::after{
        display: block;
        width: 40px;
        height: 3px;
        position: relative;
        border-radius: 3px;
        background: #000;
        transition: all .5s ease-in-out;
    }
    .burger-bar::before, .burger-bar::after{
        content: "";
        position: absolute;
        left: 0;
    }
    .burger-bar::before{
        transform: translateY(-12px);
    }
    .burger-bar::after{
        transform: translateY(12px);
    }
    .show-nav .burger-bar::before{
        transform: rotate(45deg);
    }
    .show-nav .burger-bar{
        background: transparent;
        width: 0;
    }
    .show-nav .burger-bar::after{
        transform: rotate(-45deg);
    }
    /*------Effets Bonus-------*/
    .navbar_item{
        transform: translateY(100vh);
    }
    .show-nav .navbar_item{
        transform: translateY(0);
    }
    .show-nav .slideInDown-1{
        transition: all 1s ease-out;
    }
    .show-nav .slideInDown-2{
        transition: all 1.1s ease-out;
    }
    .show-nav .slideInDown-3{
        transition: all 1.2s ease-out;
    }
    .show-nav .slideInDown-4{
        transition: all 1.3s ease-out;
    }
    .show-nav .slideInDown-5{
        transition: all 1.4s ease-out;
    }
    .show-nav .slideInDown-6{
        transition: all 1.5s ease-out;
    }
    @media screen and (max-width: 278px){
        .navbar_logo{
            font-size: 20px;
    }
}
}