@keyframes sepia {
    from {
        filter: sepia(0%);
    }
    to{
        filter: sepia(5%);
    }
}
@keyframes fade {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  *{
    -webkit-tap-highlight-color: transparent;
  }
.carousel_container{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;

}
.carousel{
    padding: 2%;
    margin-left: 2%;
    margin-right: 2%;
    height: auto;
    min-height: 45em;
    width: 100%;
    margin-bottom: 2%;
}
.carousel:hover{
    cursor: pointer;
    animation: sepia 1s forwards;
}
.carousel_wrapper{
    position: relative;
    width: 100%;
    height: 100%;
}
.title_project{
font-size: 45px;
color: white;
width: 100%;
display: flex;
justify-content: center;
padding-top: 2%;
padding-bottom: 2%;
font-weight: 500;
}
.carousel_card{
    display: flex;
    flex: 1;
    position: absolute;
    width: 100%;
    height: auto;
    overflow: hidden;
    border-radius: 10px;
    opacity: 0;
    pointer-events: none;
    transform: scale(.8);
    transition: 0.5s ease-in-out;
    padding-bottom: 7%;
    flex-direction: column;
}
.carousel_card-active{
    opacity: 1;
    transform: scale(1);
    pointer-events: visible;
}
.card_image{
    max-width: 100%;
    position: relative;
    object-fit: cover;
    width: auto;

}
.card-overlay{
    width: 100%;
    display: flex;
}
.containerContent{
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 0%;
    left: 0%;
    width: 100%;
    border-top: 1px solid black;
    background-color: white;
    color: black;
}

/* -------------------- Classes du carousel -------------------- */

/* ------------------- Title -----------------------------------*/
.card-title{
    font-size: 15px;
    margin-left: 15px;
    margin-right: 15px;
    width: auto;
    display: flex;
    align-items: flex-start;
}

/* ------------------- Content -----------------------------------*/
.card_content{
    color: #333;
    font-size: 30px;
    background-color: white;
    opacity: 0.9;
    width: 80%;
    padding-top: 25px;
    padding-bottom: 25px;
    border-radius: 15px 15px 15px 15px;
    font-style: italic;
    align-items: flex-start;
    justify-content: center;
    text-align: center;
}
.cc-white{
    color: white;
    font-size: 35px;
    border-radius: 15px 15px 15px 15px;
    text-align: center;
    margin-top: 1%;
    margin-bottom: 1%;
    height: auto;
}

/* ----------------------------------------------- */*

.carousel_arrow_left, .carousel_arrow_right{
    position: absolute;
    background-color: #eee;
    box-shadow: -6px 17px 50px -8px #696969;
    opacity: 0.9;
    border-radius: 15px 15px 15px 15px;
    padding: 15px;
    top: 45%;
    transform: translate(0, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.carousel_arrow_left{
    left: 15px;
    font-size: 40px;
    width: 40px;
    height: 40px;
}
.carousel_arrow_right{
    right: 15px;
    font-size: 40px;
    width: 40px;
    height: 40px;
}
.carousel_arrow_right:hover, .carousel_arrow_left:hover{
    opacity: 1;
    background-color: white;
}
.carousel_pagination{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 0.7em;
    margin-top: 1%;
}
.pagination_dot{
    height: 15px;
    width: 15px;
    background-color: white;
    border-radius: 50%;
    display: inline-block;
    cursor: pointer;
}
.pagination_dot:hover{
    transform: scale(1.2);
}

.pagination_dot-active{
    background-color: grey;
}

@media screen and (max-width: 1473px){
    .cc-white{
        font-size: 1.5em;
        margin-top: 2%;
    }
    .title_project{
        margin-top: 5%;
    }
    .carousel_pagination{
        gap: 0.6em;
    }
    .card_image{
        height: 550px;
    }
}
@media screen and (max-width: 1311px){
    .card_content, .cc-white{
        font-size: 25px;
    }
    .carousel{
        min-height: 50em;
    }
}
@media screen and (max-width: 1168px){
    .card_content, .cc-white{
        font-size: 20px;
        margin-bottom: 2%;
    }
    .carousel_arrow_left, .carousel_arrow_right{
        height: 30px;
        width: 30px;
        font-size: 30px;
    }
}
@media screen and (max-width: 1012px){
    .carousel_arrow_left, .carousel_arrow_right{
        font-size: 25px;
        height: 25px;
        width: 25px;
    }
    .cc-white{
        font-size: 18px;
    }
    .card_image{
        height: 400px;
    }
    .carousel{
        min-height: 40em;
    }
}
@media screen and (max-width: 851px){
    .carousel_arrow_right, .carousel_arrow_left{
        height: 25px;
        width: 25px;
        font-size: 25px;
    }
    .card_content{
        font-size: 20px;
    }
    .carousel{
        min-height: 35em;
    }
    .title_project{
        font-size: 30px;
    }
    .carousel_pagination{
        margin-top: 3%;
    }
    .card_image{
        height: 350px;
    }
}
@media screen and (max-width: 693px){
    .card_content, .cc-white{
        font-size: 17px;
    }
    .card_image{
        height: 300px;
    }
}
@media screen and (max-width: 607px){
    .card_image{
        height: 300px;
    }
    .carousel{
        min-height: 32em;
    }
    .carousel_arrow_right, .carousel_arrow_left{
        top: 45%;
    }
    .pagination_dot{
        height: 10px;
        width: 10px;
    }
    .card_image{
        margin-bottom: 0.2%;
    }
    }
    @media screen and (max-width: 414px) and (max-height: 915px){
        .carousel_arrow_right, .carousel_arrow_left{
            height: 17px;
            width: 17px;
            font-size: 17px;
        }
        .carousel_pagination{
            gap: 0.4em;
        }
        .cc-white{
            font-size: 15px;
        }
     }
    @media screen and (max-width: 414px) and (max-height: 736px){
        .carousel_arrow_right, .carousel_arrow_left{
            height: 17px;
            width: 17px;
            font-size: 17px;
        }
     }
     @media screen and (max-width: 414px){
        .carousel{
            min-height: 28em;
        }
     }
@media screen and (max-width: 391px){  
    .card_image{
        height: 200px;
    }
    .carousel_arrow_left, .carousel_arrow_right{
        height: 10px;
        width: 10px;
    }
    .carousel_arrow_left{
        left: 5px;
    }
    .carousel_arrow_right{
        right: 5px;
    }
    .title_project{
        font-size: 22px;
    }
    .carousel {
        min-height: 23em;
    }
}
@media screen and (max-width: 290px){
    .title_project{
        font-size: 1.2em;
    }
    .cc-white{
        font-size: 1em;
    }
}  
@media screen and (max-width: 260px){  
    .card_content{
        margin-left: 5px;
    }
    .card-title{
        margin-left: 5px;
    }
    .title_project{
        font-size: 1em;
    }
    .cc-white{
        font-size: 0.8em;
    }
    .carousel{
        min-height: 22em;
    }
}
@media screen and (max-width: 225px){  
    .title_project{
        font-size: 0.8em;
    }
    .cc-white{
        font-size: 0.6em;
    }
}